<template>
    <div class="progress">
        <div class="container">
            <div class="left">
                <div
                    class="left-item item"
                    v-for="(item, index) in 4"
                    :key="index"
                    :style="{
                        width: index === 0 ? '10%' : '30%',
                        borderRight: '2px solid #000'
                    }"
                >
                </div>
                <div
                    class="progress-item"
                    :style="{ width: `${score < 0 ? -score : ''}%` }"
                ></div>
            </div>
            <div class="right">
                <div
                    class="right-item item"
                    :class="index===3?'last-item':''"
                    v-for="(item, index) in 4"
                    :key="index"
                    :style="{
                        width: index === 3 ? '10%' : '30%',
                        borderRight: index !== 3 ? '2px solid #000' : ''
                    }"
                >
                </div>
                <div
                    class="progress-item"
                    :style="{ width: `${score > 0 ? score : ''}%` }"
                ></div>
            </div>
        </div>
        <div class="progress-bar">
            <div
                class="bar-item"
                :style="{
                    width: index !== 0 && index !== 7 ? '30%' : '10%'
                }"
                v-for="(item, index) in 8"
                :key="index"
            >
                <span v-if="index === 1">-90</span>
                <span v-if="index === 2">-60</span>
                <span v-if="index === 3">-30</span>
                <span v-if="index === 4">0</span>
                <span v-if="index === 5">30</span>
                <span v-if="index === 6">60</span>
                <span v-if="index === 7">90</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    score: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.progress {
    padding: 20px;
    font-size: 14px;
    .container {
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #000;
        box-sizing: border-box;
        position: relative;
        .left {
            min-height: 100px;
            width: 50%;
            display: flex;
            justify-content: space-between;
            position: relative;
            .left-item {
                position: relative;
                .bottom-words {
                    display: inline-block;
                    position: absolute;
                    right: 0;
                    bottom: -80px;
                    transform: translate(50%, 0);
                }
                .bottom-line {
                    width: 2px;
                    height: 30px;
                    background: #000;
                    position: absolute;
                    right: -2px;
                    bottom: -30px;
                }
            }
            .progress-item {
                width: 0;
                height: 60%;
                position: absolute;
                right: 2px;
                top: 50%;
                background: #ff9580;
                transform: translate(0, -50%);
            }
        }
        .right {
            width: 50%;
            min-height: 100px;
            width: 50%;
            display: flex;
            justify-content: space-between;
            position: relative;
            .right-item {
                position: relative;
                .bottom-words {
                    display: inline-block;
                    position: absolute;
                    right: 0;
                    bottom: -80px;
                    transform: translate(50%, 0);
                }
                .bottom-line {
                    width: 2px;
                    height: 30px;
                    background: #000;
                    position: absolute;
                    right: -2px;
                    bottom: -30px;
                }
            }
            .progress-item {
                width: 0;
                height: 60%;
                position: absolute;
                left: 0px;
                top: 50%;
                background: #ff9580;
                transform: translate(0, -50%);
            }
        }
        .item::after{
          width: 2px;
          height: 20px;
          content: ' ';
          position: absolute;
          right: -2px;
          bottom: -20px;
          background: #000;
        }
        .last-item::after{
          background: rgba(255, 255, 255, 0);
        }
    }
    .progress-bar-line {
        display: flex;
        height: 30px;
        width: calc(100% - 4px);
        margin: 0 auto;
        .line-item {
            box-sizing: border-box;
        }
    }
    .progress-bar {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        .bar-item {
            span {
                display: inline-block;
                text-align: center;
                transform: translate(-25%, 0);
            }
        }
    }
}
</style>
