<template>
    <div class="abilityDetails">
        <img class="img" :src="logoImg" alt="">
        <!-- 标题 -->
        <div class="title">觉 知 情 绪·疗 愈 自 我</div>
        <div class="empty"></div>
        <div class="content">
            <div class="subtitle">情绪记忆分析与建议报告</div>
            <div class="info">
                <div>姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名 : 塞德里克</div>
                <div>性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别 : 男</div>
                <div>分析时间: 2022.09.10</div>
            </div>
            <div class="line"></div>
            <div class="chinese">
                情绪的产生主要是大脑皮层下的中枢在控制，情绪是受植物神经调控的。人的植物神经系统分为交感神经系统和 副交感神经系统。
                人的植物神经系统控制内脏器官(心脏、血管、胃肠、肾等)、外部腺体(唾液、泪腺、汗腺等)与内分泌功能的变 化。植物神经系统既能接受外部感觉器官的刺激的输入，也能接受来自内脏刺激的输入。因此，人在一定的内外界的刺
                激作用下，伴随着情绪体验，人体内部发生一系列的生理变化。例如呼吸系统、循环系统、消化系统、内分泌腺、外分 泌腺及其代谢过程的相应变化。
            </div>
            <div class="line"></div>
            <div>
                <div class="pillar">
                    Core情绪检测包括四部分
                </div>
                <div class="intr">分别是情绪反应、情绪原因、情绪解析和情绪调节。</div>
                <img class="four" src="../../../assets/four.png" alt="">
            </div>

            <!-- 表格上半部分 -->
            <div class="pdf-table" v-for="(item, index) in list2" :key="index">
                <div class="pdf-table-title" v-if="item.title == 'Reaction'">{{
                    '0' + (index + 1) + '.情绪反应 | Emotional Reaction'
                }}</div>
                <div class="pdf-table-title" v-if="item.title == 'Reason'">{{
                    '0' + (index + 1) + '.情绪原因 | Emotional Reason'
                }}</div>
                <div class="pdf-table-title" v-if="item.title == 'Analysis'">{{
                    '0' + (index + 1) + '.情绪调节 | Emotional Proposal'
                }}</div>
                <div class="pdf-table-title" v-if="item.title == 'Resolution'">{{
                    '0' + (index + 1) + '.情绪解析 | Emotional Analysis'
                }}</div>
                <div class="pdf-table-title2">
                    <img class="first" src="../../../assets/first.png" alt="">
                    <div>
                        <div class="chinese" v-if="item.title == 'Reaction'">
                            情绪反应指当人们的情绪处在比较强喜、怒、悲、恐等时， 所表现出的行为或身体会发生各种不同的变化。
                        </div>
                        <div class="chinese" v-if="item.title == 'Reason'">
                            情绪是思维在身体上的反应。我们的情绪产生于大脑，情绪的产生来源于我们对事物合理和不合理的认知。
                        </div>
                        <div class="chinese" v-if="item.title == 'Analysis'">
                            是一种自然的、普遍的与生俱来的能力，是我们所有人与生俱来的神经和生理能力，能够自我调节我们的情绪创伤、恐惧、行为困难和情绪障碍
                        </div>
                        <div class="chinese" v-if="item.title == 'Resolution'">
                            我们可以通过身体上的感受来重新体验我们情感困难的根源，这也是治疗身心疾病和成瘾重要部分。
                        </div>
                    </div>
                </div>
                <!-- 表格内容 -->
                <div class="table">
                    <div class="table-top" style="background: #e0dfdf; min-height: 0">
                        <div class="table-top-one">State</div>
                        <div class="table-top-two">Value Bar</div>
                        <div class="table-top-three" style="color: #000">Percentage</div>
                    </div>
                    <div class="table-top" style="background: #f7f8f8" v-for="(value, valueIndex) in item.arr"
                        :key="valueIndex">
                        <div class="table-top-one">{{ value.sentence }}</div>
                        <div class="table-top-two">
                            <pdfitem :score="value.score" />
                        </div>
                        <div class="table-top-three">{{ value.score }}%</div>
                    </div>
                </div>
            </div>
            <div class="button-return">开启智能疗愈</div>
        </div>
    </div>
</template>

<script>
import pdfitem from './pdfitem.vue'
// function compare (key) {
//   return function (value1, value2) {
//     const val1 = Math.abs(value1[key])
//     const val2 = Math.abs(value2[key])
//     return val2 - val1
//   }
// }
export default {
  name: 'MyHealthSL',
  components: {
    pdfitem
  },
  data () {
    return {
      logoImg: 'https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/core/logo图标@2x.png',
      list2: [
        {
          title: 'Reaction',
          text: '情绪反应指当人们的情绪处在比较强喜、怒、悲、恐等时，所表现出的行为或身体发生各种不同的变化.',
          width: 30
        },
        {
          title: 'Reason',
          text: '情绪反应指当人们的情绪处在比较强喜、怒、悲、恐等时，所表现出的行为或身体发生各种不同的变化.',
          width: 80
        },
        {
          title: 'Analysis',
          text: '情绪反应指当人们的情绪处在比较强喜、怒、悲、恐等时，所表现出的行为或身体发生各种不同的变化.',
          width: 20
        },
        {
          title: 'Resolution',
          text: '情绪反应指当人们的情绪处在比较强喜、怒、悲、恐等时，所表现出的行为或身体发生各种不同的变化.',
          width: -60
        }
      ],
      report: null
    }
  },
  methods: {

  },
  mounted () {
    // this.report = uni.getStorageSync('reportDetail');
    console.log(this.report)
    // let count = 0;
    // for (const key in this.report.returnreport) {
    //     // this.list2[count] = {
    //     //     title: key,
    //     //     arr: this.report.returnreport[key]
    //     // };
    //     if (key == 'Reaction') {
    //         this.list2[0] = {
    //             title: key,
    //             arr: this.report.returnreport[key]
    //         };
    //         this.list2[0].arr.sort(compare('score'));
    //     } else if (key == 'Reason') {
    //         this.list2[1] = {
    //             title: key,
    //             arr: this.report.returnreport[key]
    //         };
    //         this.list2[1].arr.sort(compare('score'));
    //     } else if (key == 'Analysis') {
    //         this.list2[2] = {
    //             title: key,
    //             arr: this.report.returnreport[key]
    //         };
    //         this.list2[2].arr.sort(compare('score'));
    //     } else if (key == 'Resolution') {
    //         this.list2[3] = {
    //             title: key,
    //             arr: this.report.returnreport[key]
    //         };
    //         this.list2[3].arr.sort(compare('score'));
    //     }
    // }
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
.abilityDetails {
    height: 40%;
    background: url("../../../assets/modeAndHealth.png");
    background-size: 100%;
    background-color: #efefef;
    color: #fff;
    font-size: 22px;
    position: relative;

    .img {
        width: 250px;
        height: 50px;
        margin: 10px auto;
    }

    .title {
        text-align: center;
        margin-top: 10px;
        font-size: 18px;
    }

    .empty {
        width: 150px;
        height: 18px;
        background: #FF8563;
        border-radius: 10px;
        margin: 40px 10px 0 120px;
    }

    .content {
        width: 100%;
        height: 3000px;
        border-radius: 30px;
        background: #efefef;
        color: #898989;
        margin-top: -10px;

        .subtitle {
            font-size: 26px;
            text-align: center;
            padding: 20px 0;
        }

        .info {
            width: 90%;
            height: 100px;
            margin: 10px auto 20px;
            font-size: 18px;
            color: #fff;
            background-color: #8867ed;
            border-radius: 10px;

            div {
                padding: 5px 30px;
            }
        }

        .line {
            border-bottom: 1px solid #ccc;
            width: 90%;
            margin: 25px auto;
        }

        .pillar {
            position: relative;
        }

        .pillar::before {
            width: 10px;
            height: 20px;
            content: ' ';
            margin-left: 20px;
            background: #f19a85;
            display: inline-block;
            border-radius: 20px;
        }

        .chinese {
            width: 90%;
            font-size: 15px;
            margin: 10px auto;
        }

        .intr {
            font-size: 15px;
            margin: 5px 0 10px 25px;
        }

        .four {
            width: 85%;
            margin: 20px auto;
        }

        .first_intr {
            font-size: 15px;
            width: 65%;
            margin: -95px 95px;
            color: #dfd7fa;
        }

        .pdf-table {
            margin: 30px auto;
            width: 90%;

            .pdf-table-title {
                color: #898989;
                padding: px 0;
                margin-top: 50px;
                width: 350px;
            }

            .pdf-table-title::before {
                width: 10px;
                height: 20px;
                content: ' ';
                background: #f19a85;
                display: inline-block;
                border-radius: 20px;
                margin-right: 10px;
            }

            .pdf-table-title2 {
                margin: 20px 0;
                display: flex;
                width: 100%;
                height: 150px;
                background: #8867ed;
                border-radius: 30px;
                color: white;
                justify-content: space-between;
                align-items: center;

                .first {
                    width: 90%;
                    height: 80px;
                    margin: 10px auto;
                }

                .chinese {
                    width: 240px;
                    margin: 110px 0 0px -250px;
                    height: 180px;
                    font-size: 15px;
                    color: #e7e0fb;
                }

                .big-words {
                    font-size: 160px;
                    font-weight: 600;
                    color: rgba(255, 255, 255, 0.356);
                    margin-top: -60px;
                    height: 100%;
                    border-right: 2px solid rgba(255, 255, 255, 0.356);
                }
            }

            .table {
                .table-top {
                    display: flex;
                    font-size: 18px;
                    min-height: 170px;

                    .table-top-one {
                        width: 30%;
                        border: 3px solid white;
                        padding: 20px;
                    }

                    .table-top-two {
                        width: 50%;
                        border: 3px solid white;
                        // padding: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        // pdfitem {
                        //     width: 100%;
                        // }
                    }

                    .table-top-three {
                        width: 30%;
                        border: 3px solid white;
                        padding: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #ff9580;
                        font-size: 18px;
                    }
                }
            }
        }

        .button-return {
            background: linear-gradient(to left top, #ff8a69, #ffd5c3);
            width: 60%;
            height:70px;
            line-height: 70px;
            border-radius: 50px;
            font-size: 25px;
            color: white;
            text-align: center;
            margin: 50px auto;
        }
    }

}
</style>
